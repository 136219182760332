import React, { useState } from "react";
import "./PdfModal.css"; // For modal styles

const PdfModal = ({ pdfUrl, onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <iframe          
          src="https://drive.google.com/file/d/1xFnx9M4IOoC6zqJanAn8zx5UiIVHxRjq/preview"
          title="Launch Agenda"
          width="100%"
          height="650px"
          allow="autoplay"
        />
      </div>
    </div>
  );
};

export default PdfModal;
