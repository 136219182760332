import React, { useState, useEffect } from "react";
import "./style.css";
import PdfModal from "../../Components/PdfModal";

const Launch = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const pdfUrl = "https://coverton.in/downloads/Document.pdf"; // URL to the PDF file

  const openModal1 = () => setIsModalOpen1(true);
  const closeModal1 = () => setIsModalOpen1(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [statusMessage, setStatusMessage] = useState("");
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const buttons = [
    {
      text: "Coverton Website",
      link: "https://www.coverton.in/",
      iconLeft: "fa-globe",
      iconRight: "none",
    },
    {
      text: "Launch Agenda",
      link: "#",
      onClick: () => setIsModalOpen1(true),
      iconLeft: "fa-clipboard",
      iconRight: "fa-downloads",
    },
    {
      text: "RSVP Form",
      link: "https://forms.gle/u8EZoWQxmKqo3PVC8",
      iconLeft: "fa-file",
      iconRight: "none",
    },
    {
      text: "Event Location",
      link: "https://maps.app.goo.gl/pLU48p53Twu3ECY19",
      iconLeft: "fa-location-dot",
      iconRight: "none",
    },
    {
      text: "Call Us",
      link: "tel:+919566085116",
      iconLeft: "fa-phone",
      iconRight: "none",
    },
    {
      text: "Any Query",
      link: "#",
      onClick: () => setIsModalOpen(true),
      iconLeft: "fa-message",
      iconRight: "none",
    },
    {
      text: "LinkedIn",
      link: "https://www.linkedin.com/company/coverton-insurance/",
      iconLeft: "fa-linkedin",
      iconRight: "none",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    const targetDate = new Date("2024-11-15T18:00:00");

    const updateCountdown = () => {
      const now = new Date().getTime();
      const difference = targetDate.getTime() - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000),
        });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    updateCountdown(); // Update immediately before setting interval
    const timer = setInterval(updateCountdown, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage("Sending...");

    try {
      const response = await fetch("https://coverton.in/launch.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.success) {
        setStatusMessage("Message sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      } else {
        setStatusMessage("Failed to send message. Please try again.");
      }
    } catch (error) {
      setStatusMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="container">
      <h1 className="d-flex-x-y-center">COVERTON</h1>
      <h1 className="d-flex-x-y-center">Curtain Raiser 2024</h1>
      <div className="countdown">
        <h2>Countdown to Launch:</h2>
        <p>
          {timeLeft.days}d : {timeLeft.hours}h : {timeLeft.minutes}m :{" "}
          {timeLeft.seconds}s
        </p>
      </div>
      <div>
        {isModalOpen1 && <PdfModal pdfUrl={pdfUrl}  onClose={closeModal1} />}
      </div>
      <div className="button-list">
        {buttons.map((button, index) => (
          <a
            key={index}
            href={button.link}
            className="custom-button"
            target={button.text === "Launch Agendas" ? "_blank" : "_self"}
            rel="noopener noreferrer"
            onClick={button.onClick}
          >
            <span className="icon-left">
              <i className={`fas ${button.iconLeft}`}></i>
            </span>
            {button.text}
            {button.iconRight && (
              <span className="icon-right">
                <i className={`fas ${button.iconRight}`}></i>
              </span>
            )}
          </a>
        ))}
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span
              className="close-button"
              onClick={() => setIsModalOpen(false)}
            >
              &times;
            </span>
            <h1 className="modal-heading">Contact Us</h1>
            <form onSubmit={handleSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Message:
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </label>
              <button type="submit" className="submit-button">
                Submit
              </button>
              <p className="response-message">{statusMessage}</p>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Launch;
