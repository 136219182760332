import React, { Suspense, lazy } from "react";
import {
  Route,
  Routes,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// common Components
import Loader from "../Components/Loader";
import Layout from "../Pages/CommonLayout/Index";
import ScrollToTop from "../Components/ScrollToTop";
import Home from "../Pages/Home/Home";
import AboutUs from "../Pages/AboutUs/AboutUs";
import Launch from "../Pages/Launch";
// lazy components
// const Home = lazy(() => import("../Pages/Home/Home"));
// const AboutUs = lazy(() => import("../Pages/AboutUs/AboutUs"));

function MainRouter() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Layout>
          <Suspense fallback={<Loader />}>
            <ScrollToTop />
            <Home />
          </Suspense>
        </Layout>
      ),
    },  
    {
      path: "about-us",
      element: (
        <Layout>
          <Suspense fallback={<Loader />}>
            <ScrollToTop />
            <AboutUs />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "launch",
      element: (
        <Layout>
          <Suspense fallback={<Loader />}>
            <ScrollToTop />
            <Launch />
          </Suspense>
        </Layout>
      ),
    },
  ]);
  return <RouterProvider router={router} />;
}

export default MainRouter;
